<template>
  <section>
    <v-row id="method" v-if="!shipping && !delivery">
      <v-col md-6 class="">
        <h3>New Shipping Method</h3>
        <small>Select how you ship your goods</small>
      </v-col>
      <v-col md-6 class="text-right">
        <router-link
            :to="{ name: 'shipment-setup', params: { domainname: siteUrl }}"
            outlined
            class="btn btn-lg btn-secondary-main"
            style="background:transparent;"
        >
          <i class="fas fa-arrow-left"></i> Back
        </router-link>
      </v-col>
      <v-col cols="12">
        <v-card class="method-card">
          <v-row>
            <!-- <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-flag"></i>
                  Nepal Post
                </div>
                <div class="card-text">
                  <p>Choose this option if you either deliver goods yourself or use a local delivery service.</p>
                </div>
                <v-btn outlined dense class="setup-button" @click="setupShipping">Set Up Nepal Post</v-btn>
              </v-card-text>
            </v-col>-->
          </v-row>
        </v-card>

        <v-card class="method-card">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-biking"></i>
                  Local delivery
                </div>
                <div class="card-text">
                  <p>Choose this option if you either deliver goods yourself or use a local delivery service.</p>
                </div>
                <v-btn
                  outlined
                  dense
                  class="setup-button"
                  @click="setupDelivery"
                  :loading="loading"
                >Set Up Local delivery</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="method-card">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-truck"></i>
                  Other carriers
                </div>
                <div class="card-text">
                  <p>Enter the name of the carrier that you would like to set up.</p>
                  <br />
                  <v-text-field v-model="carrier_name" outlined dense label="Carrier Name"></v-text-field>
                  <span class="text-danger" v-if="$v.carrier_name.$error">
                    <strong>Carrier Name is required</strong>
                  </span>
                </div>
                <v-btn
                  outlined
                  dense
                  class="setup-button"
                  @click="setUpOtherCarrier"
                  :loading="loading"
                >Set Up Other Carrier</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row id="method">
      <v-col cols="12">
        <v-card class="method-card" v-if="shipping">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-flag"></i>
                  Free shipping
                </div>
                <div class="card-text">
                  <p>Set up a free shipping if you include the shipping cost into the products' prices or if you cover the expenses in other ways. Offering a free shipping encourages customers to complete their orders.</p>
                </div>
                <v-btn outlined dense class="setup-button">Set Up Free Shipping</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card class="method-card" v-if="shipping">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-flag"></i>
                  Conditional Free shipping
                </div>
                <div class="card-text">
                  <p>Offer free shipping for all orders over Rs100.00. Charge Rs5.00 for orders under that amount. Or set your own rates and conditions.</p>
                </div>
                <v-btn outlined dense class="setup-button">Set Up Free Shipping</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>-->
        <v-card class="method-card" v-if="delivery">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-flag"></i>
                  Free Delivery
                </div>
                <div class="card-text">
                  <p>Set up a free shipping if you include the shipping cost into the products' prices or if you cover the expenses in other ways. Offering a free shipping encourages customers to complete their orders.</p>
                </div>
                <v-btn
                  outlined
                  dense
                  class="setup-button"
                  @click="routeTo('shipping-method-type-free')"
                >Set Up Free Shipping</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="method-card" v-if="delivery">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-flag"></i>
                  Conditional Free Delivery
                </div>
                <div class="card-text">
                  <p>Offer free shipping for all orders over {{currency_symbol}}100.00. Charge {{currency_symbol}}5.00 for orders under that amount. Or set your own rates and conditions.</p>
                </div>
                <v-btn
                  @click="routeTo('shipping-method-type-conditional')"
                  outlined
                  dense
                  class="setup-button"
                >Set Up Free Shipping</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>

        <v-card class="method-card" v-if="delivery || shipping">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-biking"></i>
                  Flat Rates
                </div>
                <div class="card-text">
                  <p>Set up a flat rate to charge a fixed shipping fee on all orders no matter how many items are ordered.</p>
                </div>
                <v-btn
                  @click="routeTo('shipping-method-type-flat')"
                  outlined
                  dense
                  class="setup-button"
                >Set Up Local delivery</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-card class="method-card" v-if="delivery || shipping">
          <v-row>
            <v-col cols="12">
              <v-card-text>
                <div class="card-title">
                  <i class="method-icon fa fa-truck"></i>
                  Custom rates
                </div>
                <div class="card-text">
                  <p>Set your own shipping cost calculation rules based on order price, number of items, or weight.</p>
                  <br />
                </div>
                <v-btn
                  @click="routeTo('shipping-method-type-custom')"
                  outlined
                  dense
                  class="setup-button"
                >Set Up</v-btn>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
        <v-btn
          v-if="shipping || delivery"
          outlined
          dense
          class="pull-right mt-5"
          @click="shippingMethod"
        >Back to Shipping</v-btn>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Free from "./MethodTypes/Free";
import Conditional from "./MethodTypes/Conditional";
import FlatRate from "./MethodTypes/FlatRate";
import CustomRate from "./MethodTypes/CustomRate";

export default {
  validations: {
    carrier_name: { required }
  },
  components: {
    Free,
    Conditional,
    FlatRate,
    CustomRate
  },
  data() {
    return {
      shipping: false,
      delivery: false,
      carrier_name: null,
      loading: false
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currency_symbol() {
      return this.currentUser.store_config.currency_symbol;
    },
    siteUrl() {
      return this.$route.params.domainname;
    },
  },
  mounted() {
    // console.log(this.currentUser);
  },
  methods: {
    setupShipping() {
      setTimeout(() => {
        this.shipping = true;
        this.delivery = false;
      }, 500);
    },
    setupDelivery() {
      setTimeout(() => {
        this.delivery = true;
        this.shipping = false;
      }, 500);
    },
    shippingMethod() {
      setTimeout(() => {
        this.delivery = false;
        this.shipping = false;
      }, 500);
    },
    routeTo(route) {
      if (
        this.carrier_name != null &&
        this.carrier_name != undefined &&
        this.carrier_name != ""
      ) {
        let name = null;
        switch (route) {
          case "shipping-method-type-free":
            name = "shipping-method-other-flat";
            break;
          case "shipping-method-type-conditional":
            name = "shipping-method-other-conditional";
            break;
          case "shipping-method-type-flat":
            name = "shipping-method-other-custom";
            break;
          case "shipping-method-type-custom":
            name = "shipping-method-other-flat";
            break;
        }
        this.$router.push({
          name: name,
          params: { carrier_name: this.carrier_name }
        });
      } else {
        this.$router.push({
          name: route
        });
      }
    },
    setUpOtherCarrier() {
      this.$v.carrier_name.$touch();
      if (this.$v.carrier_name.$error) {
        setTimeout(() => {
          this.$v.carrier_name.$reset();
        }, 3000);
      } else {
        this.delivery = true;
        this.shipping = false;
      }
    }
  }
};
</script>
<style scoped>
.method-card {
  margin-top: 20px;
}
#method .card-title {
  font-size: 25px;
  font-weight: 600;
}
#method .card-text {
  font-size: 12px;
  font-weight: 500;
  width: 50%;
}
#method .method-icon {
  margin: auto 10px;
  color: rgb(31, 163, 236);
}
#method .heading h1 {
  font-weight: 600;
}
#method .heading p {
  font-size: 14px;
  font-weight: 600;
}
#method .setup-button {
  float: right;
  bottom: 50px;
  right: 30px;
  color: rgb(31, 163, 236);
}
</style>
